@import "scss/index";
.checkBox {
  text-align: left;
  vertical-align: top;
  cursor: pointer;
  @include no-user-select;

  &.disabled {
    cursor: not-allowed;
  }
}

$shape-color-light: transparentize($color-rocky, 0.92);

.textArea {

  &:not(.filled) {
    .label {
      top: 10px;
      left: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.disabled {
    opacity: 0.48;
  }

  .label,
  .arrow,
  .icon {
    color: transparentize($color-titanic, 0.52);
  }

  @media (hover: hover) {
    &:hover:not(.disabled):not(.errored) {
      background-color: figmaLighten($shape-color-light, 3%);

      .arrow,
      .label {
        color: transparentize($color-titanic, 0.44);
      }
    }
  }

  &.focused {
    background-color: figmaDarken($shape-color-light, 3%);

    .arrow {
      color: $color-rocky;
    }
    .text {
      color: $color-titanic;
    }
    .label {
      color: transparentize($color-titanic, 0.44);
    }
  }

  &.errored {
    background-color: transparentize($color-fargo, 0.92);

    .arrow,
    .text {
      color: $color-prestige;
    }

    .label {
      color: transparentize($color-prestige, 0.52);
    }

    &.filled.focused {
      .label {
        color: transparentize($color-prestige, 0.44);
      }
    }
  }

  &.filled:not(.errored) {
    background-color: figmaDarken($shape-color-light, 3%);

    .icon {
      color: $color-rocky;
    }

    .text {
      color: transparentize($color-titanic, 0.28)
    }
  }
}

.textArea {
  // width: 100%;
  height: 40px;
  padding: 0 16px;
  background-color: $shape-color-light;


  .text {
    // width: 98%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
  }

  @media (hover: hover) {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
  }
  .arrow {
    flex-shrink: 0;
  }
}

.textContent {
  // flex-grow: 1;
  height: 100%;
  padding: 2px 0;
}

.label {
  width: 100%;
  @include basic-transition(all);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  line-height: 16px;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text {
  &, &:focus {
    background-color: inherit;
  }
  appearance: none;
  caret-color: $color-fargo;
  @media (hover: hover) {
    cursor: pointer;
  }
  &[disabled] {
    cursor: default;
  }
}


.options {
  width: 100%;
  top: 4px;
  max-height: 450px;
  box-shadow: 0 9px 28px rgba(17, 48, 121, 0.18);
  background-color: $color-amelie;
  z-index: 999; // TODO change to min zIndex
  overflow: auto;

  &.top {
    top: auto;
    bottom: 44px;
  }
}

.option {

  //&:focus-visible {
  //  outline: none;
  //}

  .text {
    max-width: 98%;
  }
  
  .title {
    //color: $color-titanic;
    opacity: 0.72;
  }

  .subTitle {
    //color: $color-titanic;
    opacity: 0.48;
  }

  @media(hover: hover) {
    &:hover:not(.disabled) {
      .title, .subTitle {
        opacity: 1;
      }
    }
  }

  &.disabled {
    opacity: 0.16;
    cursor: default;
  }

  .indicator.first {
    border-top-left-radius: 8px;
  }  

  .indicator.last {
    border-bottom-left-radius: 8px;
  }

  .indicator {
    height: 44px;
    width: 4px;
    min-width: 4px;
    background-color: $color-amelie;
  }

  @media(hover: hover) {
    &:hover:not(.disabled) {
      cursor: pointer;
      
      .indicator {
        background-color: $color-rocky;
      }
    }
  }  
}

// scrollbar

// firefox
.options {
  scrollbar-color: transparentize($color-titanic, 0.92) transparent;
  scrollbar-width: thin;
}

// the rest, besides IE
.options::-webkit-scrollbar-track
{
  background-color: transparent;
}

.options::-webkit-scrollbar
{
  width: 4px;
  height: 25%;
}

.options::-webkit-scrollbar-thumb
{
	background-color: transparentize($color-titanic, 0.92);
	border-radius: 2px;
}
