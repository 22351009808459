@import "scss/index";
.container:hover{
  border: 1px dashed $color-godfather;
  cursor: pointer;
}

.container {
  border: 1px dashed $color-titanic;
  cursor: pointer;

  &.uploading,
  &.uploaded {
    border: 1px dashed rgba($color-titanic, 0);
  }
}

.status {
  width: 0;
  transition: width 1.5s ease-in-out;
}

.uploading {

  .status {
    width: 50%;
    background-color: $color-rocky;
  }
}

.uploaded {

  .status {
    width: 100%;
    background-color: $color-rocky;
    transition: width 0.5s ease-in-out;
  }
}
