@import "scss/index";
.link {
  @include no-user-select;
  @include keyboard-focus;
}

// Colors
@mixin link-color($color) {
  border-bottom: 2px solid $color;

  @media (hover: hover) {

    &:hover,
    &:active {
      border-color: transparent;

      .text {
        background-color: $color;
      }
    }

    @include keyboard-focus {
      .text {
        background-color: $color;
      }
    }
  }
}

.color-alien {
  @include link-color($color-alien);
}

.color-indiana {
  @include link-color($color-indiana);
}

.color-drive {
  @include link-color($color-drive);
}

.color-chinatown {
  @include link-color($color-chinatown);
}
