@import "scss/index";
.tag {
  @include no-user-select;
  @include keyboard-focus;

  &:hover,
  &:focus {
    transition: all 0s $ease-out-quadratic;
  }
}

.close {
  color: $color-white;
  transition: opacity 0.1s ease;

  &:hover {
    opacity: 1;
  }
}

@mixin dot-color($color) {
  background: linear-gradient(90deg, rgba($color, 0) -18.75%, $color 100%);
}

.color-rocky {
  @include dot-color($color-rocky);
}

.color-alien {
  @include dot-color($color-alien);
}

.color-lebowski {
  @include dot-color($color-lebowski);
}

.color-snatch {
  @include dot-color($color-snatch);
}

.color-matrix {
  @include dot-color($color-matrix);
}

.color-drive {
  @include dot-color($color-drive);
}

.color-fargo {
  @include dot-color($color-fargo);
}

.color-indiana {
  @include dot-color($color-indiana);
}
