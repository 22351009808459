@import "scss/index";
$shape-color-light: transparentize($color-rocky, 0.92);

.textArea {

  &:not(.filled) {
    .label {
      top: 10px;
      left: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.disabled {
    opacity: 0.48;
  }

  .label,
  .arrow,
  .icon {
    color: transparentize($color-titanic, 0.52);
  }

  @media (hover: hover) {
    &:hover:not(.disabled):not(.errored) {
      background-color: figmaLighten($shape-color-light, 3%);

      .arrow,
      .label {
        color: transparentize($color-titanic, 0.44);
      }
    }
  }

  &.focused {
    background-color: figmaDarken($shape-color-light, 3%);

    .arrow {
      color: $color-rocky;
    }
    .text {
      color: $color-titanic;
    }
    .label {
      color: transparentize($color-titanic, 0.44);
    }
  }

  &.errored {
    background-color: transparentize($color-fargo, 0.92);

    .arrow,
    .text {
      color: $color-prestige;
    }

    .label {
      color: transparentize($color-prestige, 0.52);
    }

    &.filled.focused {
      .label {
        color: transparentize($color-prestige, 0.44);
      }
    }
  }

  &.filled:not(.errored) {
    background-color: figmaDarken($shape-color-light, 3%);

    .icon {
      color: $color-rocky;
    }

    .text {
      color: transparentize($color-titanic, 0.28)
    }
  }
}

.textArea {
  // width: 100%;
  height: 40px;
  padding: 0 16px;
  background-color: $shape-color-light;


  .text {
    // width: 98%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
  }

  @media (hover: hover) {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
    .text {
      cursor: default;
    }
  }
  .arrow {
    flex-shrink: 0;
  }
}

.textContent {
  // flex-grow: 1;
  height: 100%;
  padding: 2px 0;
}

.label {
  width: 100%;
  @include basic-transition(all);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  line-height: 16px;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text {
  &, &:focus {
    background-color: inherit;
  }
  appearance: none;
  caret-color: $color-fargo;
  @media (hover: hover) {
    cursor: pointer;
  }
}

.note,
.error {
  margin-left: 17px;
  font-size: 13px;
  line-height: 16px;
}

.note {
  color: $color-twitter;
}

.error {
  color: $color-fargo;
}

