@import "scss/index";
.days {
  margin: 0 -8px;
}

.day {
  width: 20px;
  height: 16px;

  &::before {
    position: absolute;
    top: -4px;
    left: -2px;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    content: '';
  }

  > div {
    z-index: 2;
  }

  &.picked {
    &::before {
      background-color: $color-jaws;
    }

    > div {
      color: $color-lebowski;
    }
  }
  
  &.hoverable:hover, &.hovered {

    &::before {
      background-color: $color-arrival;
    }

    > div {
      color: $color-rocky;
    }
  }

  &.selected,
  &.selected:hover {

    &::before {
      background-color: $color-rocky;
    }

    > div {
      color: $color-white;
    }
  }

  > *::selection {
    background-color: transparent !important;
  }
}

.weekDay {
  width: 20px;
  height: 16px;
}

.rotate {
  transform: rotate(180deg);
}
