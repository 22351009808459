@import "scss/index";

.options {
  width: 100%;
  top: 4px;
  max-height: 450px;
  box-shadow: 0 9px 28px rgba(17, 48, 121, 0.18);
  background-color: $color-amelie;
  z-index: 999; // TODO change to min zIndex
  overflow: auto;

  &.top {
    top: auto;
    bottom: 44px;

    &.withoutButton {
      bottom: 16px;
    }
  }
}

.option {

  //&:focus-visible {
  //  outline: none;
  //}

  .text {
    max-width: 98%;
  }

  .title {
    //color: $color-titanic;
    opacity: 0.72;
  }

  .subTitle {
    //color: $color-titanic;
    opacity: 0.48;
  }

  @media(hover: hover) {
    &:hover:not(.disabled) {
      .title, .subTitle {
        opacity: 1;
      }
    }
  }

  &.disabled {
    opacity: 0.16;
    cursor: default;
  }

  .indicator.first {
    border-top-left-radius: 8px;
  }

  .indicator.last {
    border-bottom-left-radius: 8px;
  }

  .indicator {
    height: 44px;
    width: 4px;
    min-width: 4px;
    background-color: $color-amelie;
  }

  @media(hover: hover) {
    &:hover:not(.disabled) {
      cursor: pointer;

      .indicator {
        background-color: $color-rocky;
      }
    }
  }
}

.options {
  @include uikit-scrollbar;
}