@import "scss/index";
.dot {
  width: 8px;
  height: 8px;
  background-color: $color-white;
  opacity: 0.16;

  .active & {
    opacity: 1;
  }

  .tag:not(.active) {

    &:hover,
    &:active {

      & {
        opacity: 0.48;
      }
    }
  }
}
