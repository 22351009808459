@import "scss/index";
.actionButton {
  transition: all 0.25s $ease-out-quadratic;
  @include no-user-select;
  @include keyboard-focus;

  &:not(.disabled) {

    &:hover,
    &:focus {
      transition: all 0s $ease-out-quadratic;
    }
  }
}

.disabled {
  cursor: default !important;
  opacity: 0.4;
}

@mixin action-button-color($color) {
  color: $color !important;

  &:not(.disabled) {
    @include button-colors($color, color);
  }
}

.color-rocky {
  @include action-button-color($color-rocky);
}

.color-titanic {
  @include action-button-color($color-titanic);
}

.color-fargo {
  @include action-button-color($color-fargo);
}

.color-mulholland {
  @include action-button-color($color-mulholland);
}

.color-platoon {
  @include action-button-color($color-platoon);
}

.color-lebowski {
  @include action-button-color($color-lebowski);
}

.size-c16 {
  height: 20px;
}

.size-c13 {
  height: 16px;
}
