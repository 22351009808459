@import "scss/index";
.textEditor {
  :global(.ql-editor) {
    padding: 10px 16px;
    background-color: transparentize($color-rocky, 0.92);
    border-radius: 8px;
    caret-color: $color-fargo;
    border: none;
  
    p, ol, ul {
      font-size: 16px;
      line-height: 20px;
      padding: 0;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }

    a {
      border-bottom: 2px solid $color-alien;
    }
  }

  :global(.ql-editor.ql-blank::before) {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    font-style: normal;
    left: 16px;
    top: 12px;
  }
  
  button {
    width: 32px;
    height: 32px;
    border-radius: 2px;

    &:global(.ql-active) {
      background-color: transparentize($color-rocky, 0.92);
    }
  }
}

.fullHeight {
  height: calc(100% - 56px);
}

// scrollbar

// firefox
:global(.ql-editor) {
  &{
    scrollbar-color: transparentize($color-titanic, 0.92) transparent;
    scrollbar-width: thin;
  }
  
  // the rest, besides IE
  &::-webkit-scrollbar-track
  {
    background-color: transparent;
  }
  
  &::-webkit-scrollbar
  {
    width: 4px;
    height: 25%;
  }
  
  &::-webkit-scrollbar-thumb
  {
    background-color: transparentize($color-titanic, 0.92);
    border-radius: 2px;
  }
}