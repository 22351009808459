@import "scss/index";
.arrowButton {
  height: 20px;
  transition: all 0.25s $ease-out-quadratic;
  @include no-user-select;
  @include keyboard-focus;

  &:not(.disabled) {

    &:hover,
    &:focus {
      transition: all 0s $ease-out-quadratic;
    }
  }
}

.disabled {
  cursor: default !important;
  opacity: 0.4;
}

@mixin arrow-button-color($color) {
  color: $color;

  &:not(.disabled) {
    @include button-colors($color, color);
  }
}

.color-rocky {
  @include arrow-button-color($color-rocky);
}

.color-titanic {
  @include arrow-button-color($color-titanic);
}

.color-lebowski {
  @include arrow-button-color($color-lebowski);
}

.icon {
  margin-right: 6px;
}

.size-c16 {
  height: 20px;
}

.size-c13 {
  height: 16px;
}
