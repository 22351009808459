@import "scss/index";
.switch {
  background-color: $color-rocky;
  outline: none;
  cursor: pointer;
}

.handle {
  position: absolute;
  background-color: $color-amelie;
  border-radius: 50%;
  @include basic-transition(left);
}


.size-20 {
  width: 32px;
  height: 20px;
  border-radius: 10px;

  &.active {

    .handle {
      left: calc(100% - 18px);
    }
  }

  .handle {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
  }
}
