@import "scss/index";
.root {
  text-align: left;
  vertical-align: top;
  cursor: pointer;
  @include no-user-select;

  &.disabled {
    cursor: not-allowed;
    opacity: .32;
  }
}
