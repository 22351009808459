@import "scss/index";
.knob {
  transition: all 0.25s $ease-out-quadratic;
  @include no-user-select;
  @include keyboard-focus;

  &:not(.disabled) {

    &:hover,
    &:focus {
      transition: all 0s $ease-out-quadratic;
    }
  }
}

.disabled {
  cursor: default !important;
}

// Styles
@mixin knob-color($color, $opacity) {
  background-color: $color;

  &:not(.disabled) {
    @include button-colors($color, background-color);
  }

  &.disabled {
    opacity: $opacity;
  }
}

.primary {
  @include knob-color($color-rocky, 0.4);
}

.secondary {
  @include knob-color($color-arrival, 0.48);
}

.white {
  @include knob-color($color-amelie, 1);

  &.disabled {
    opacity: 0.4;
  }
}

// Sizes

.size-72 {
  width: 72px;
  height: 72px;
}

.size-64 {
  width: 64px;
  height: 64px;
}

.size-40 {
  width: 40px;
  height: 40px;
}

.size-32 {
  width: 32px;
  height: 32px;
}

.size-24 {
  width: 24px;
  height: 24px;
}
