@import "scss/index";
.linkButton {
  height: 40px;
  transition: all 0.25s $ease-out-quadratic;
  @include no-user-select;
  @include keyboard-focus;

  &:not(.disabled) {

    &:hover,
    &:focus {
      transition: all 0s $ease-out-quadratic;
    }
  }

  // COLORS
  background-color: $color-arrival;

  @media (hover: hover) {

    &:not(.disabled) {

      &:hover,
      &:active {
        background-color: figmaLighten($color-arrival, 3);

        .text {
          color: $color-rocky;
          border-bottom: 2px dotted $color-rocky;
        }
      }

      //&:hover:active {
      //  background-color: figmaDarken($color-arrival, 3);
      //}

      @include keyboard-focus {
        background-color: #f6f9fe;
      }
    }
  }
}

.disabled {
  cursor: default !important;
  opacity: 0.4;
}

.text {
  color: $color-godfather;
  border-bottom: 2px dotted $color-godfather;
}
