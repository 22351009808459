@import "scss/index";
.close {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) -18.75%, #FFFFFF 100%);
  transition: opacity 0.1s ease;

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    opacity: 0;

    .tag:hover & {
      opacity: 1;
    }
  }
}
