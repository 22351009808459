@import "scss/index";
.headerButton {
  transition: all 0.25s $ease-out-quadratic;
  @include no-user-select;
  @include keyboard-focus;


  &:not(.disabled) {

    &:hover,
    &:focus {
      transition: all 0s $ease-out-quadratic;
      color: $color-godfather;
    }

    &:active {
      color: figmaLighten($color-godfather, 3)
    }
  }
}

.disabled {
  cursor: default !important;
  opacity: 0.4;
}