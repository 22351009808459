@import "scss/index";
.tag {
  @include no-user-select;
  @include keyboard-focus;

  &:hover,
  &:focus {
    transition: all 0s $ease-out-quadratic;
  }
}

@mixin tag-color($color) {
  background-color: $color;

  &.hover {
    &:hover {
      background-color: figmaLighten($color, 3%);
    }

    &:active {
      background-color: figmaDarken($color, 3%);
    }
  }
}

.color-rocky {
  @include tag-color($color-rocky);
}

.color-alien {
  @include tag-color($color-alien);
}

.color-lebowski {
  @include tag-color($color-lebowski);
}

.color-snatch {
  @include tag-color($color-snatch);
}

.color-matrix {
  @include tag-color($color-matrix);
}

.color-drive {
  @include tag-color($color-drive);
}

.color-fargo {
  @include tag-color($color-fargo);
}

.color-indiana {
  @include tag-color($color-indiana);
}
