@import "scss/index";
.button {
  position: relative;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  transition: all 0.25s $ease-out-quadratic;
  @include no-user-select;
  @include keyboard-focus;

  &:not(.disabled):not(.loading) {

    &:hover,
    &:focus {
      transition: all 0s $ease-out-quadratic;
    }
  }
}

// Styles

@mixin button-style-color($color, $opacity) {
  background-color: $color;

  &:not(.disabled):not(.loading) {
    @include button-colors($color, background-color);
  }

  &.disabled {
    opacity: $opacity;
  }
}

.primary {
  @include button-style-color($color-rocky, 0.4);
}

.secondary {
  @include button-style-color($color-arrival, 0.48);
}

.accent {
  @include button-style-color($color-fargo, 0.32);
}

// Sizes

.size-56 {
  height: 56px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
}

.size-48 {
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
}

.size-40 {
  height: 40px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
}

.size-24 {
  height: 24px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border-radius: 4px;
}


// Modifies

.loading,
.disabled {
  cursor: default;
}
