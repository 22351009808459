@import "scss/index";
.container {
  width: 120px;
  height: 256px;
  background-color: $color-white;
}

.days {
  margin: 0 -8px;
}

.weekDay {
  width: 20px;
  height: 16px;
}

.rotate {
  transform: rotate(180deg);
}

.timeSlotSlider {
  @include uikit-scrollbar;
  height: 200px;
  overflow: auto;
}