@import "scss/index";
$shape-color-light: transparentize($color-rocky, 0.92);

.inputArea {

  &:not(.filled):not(.focused) {
    .label {
      top: 10px;
      left: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.disabled {
    opacity: 0.48;
  }

  .label,
  .icon {
    color: transparentize($color-titanic, 0.52);
  }

  &.style-light {
    background-color: $shape-color-light;

    @media (hover: hover) {
      &:hover {
        background-color: figmaLighten($shape-color-light, 3%);

        .icon,
        .label {
          color: transparentize($color-titanic, 0.44);
        }
      }
    }

    &.focused {
      background-color: figmaDarken($shape-color-light, 3%);

      .icon {
        color: $color-rocky;
      }
      .input {
        color: $color-titanic;
      }
      .label {
        color: transparentize($color-titanic, 0.44);
      }
    }

    &.errored {
      background-color: transparentize($color-fargo, 0.92);

      .icon,
      .input {
        color: $color-prestige;
      }

      .label {
        color: transparentize($color-prestige, 0.52);
      }

      &.filled.focused {
        .label {
          color: transparentize($color-prestige, 0.44);
        }
      }
    }

    &.filled:not(.errored):not(.focused) {
      background-color: figmaDarken($shape-color-light, 3%);
      .input {
        color: transparentize($color-titanic, 0.28)
      }
    }
  }

  &.style-dark {
    background-color: $color-amelie;

    @media (hover: hover) {
      &:hover {
        background-color: $color-amelie;

        .icon,
        .label {
          color: transparentize($color-titanic, 0.44);
        }
      }
    }

    &.focused {
      .label {
        color: transparentize($color-titanic, 0.52);
      }
    }

    // &.errored {
    //   background-color: transparentize($color-fargo, 0.92);

    //   .icon, .input {
    //     color: $color-prestige;
    //   }

    //   .label {
    //     color: transparentize($color-prestige, 0.52);
    //   }

    //   &.filled.focused {
    //     .label {
    //       color: transparentize($color-prestige, 0.44);
    //     }
    //   }
    // }

    &.filled:not(.errored) {
      .input {
        color: transparentize($color-titanic, 0.28)
      }
    }
  }
}

.inputArea {
  width: 100%;
  height: 40px;
  padding: 0 16px;

  .input {
    font-size: 16px;
    line-height: 20px;
  }

  @media (hover: hover) {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
  }
  .icon {
    flex-shrink: 0;
  }
}

.textContent {
  flex-grow: 1;
  height: 100%;
  padding: 2px 0;
}

.label {
  width: 100%;
  @include basic-transition(all);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  line-height: 16px;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &, &:focus {
    background-color: inherit;
  }
  appearance: none;
  caret-color: $color-fargo;
  @media (hover: hover) {
    cursor: pointer;
  }
  &[disabled] {
    cursor: default;
  }
}

.note,
.error {
  margin-left: 17px;
  font-size: 13px;
  line-height: 16px;
}

.note {
  color: $color-titanic;
  opacity: 0.72;
}

.error {
  color: $color-fargo;
}

.picker {
  position: absolute;
  z-index: 10;

  &.top {
    bottom: 40px;
  }
}
